import { twMerge } from 'tailwind-merge';

import type { CSSProperties, PropsWithChildren } from 'react';

type TextSizes =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl';

type TextLines = 1 | 2 | 3 | 4 | 5 | 6;

export interface TextProps extends PropsWithChildren {
  as?:
    | 'p'
    | 'span'
    | 'b'
    | 'i'
    | 'u'
    | 'abbr'
    | 'cite'
    | 'del'
    | 'em'
    | 'ins'
    | 'kbd'
    | 'mark'
    | 's'
    | 'samp'
    | 'sub'
    | 'sup';
  className?: string;
  noOfLines?: TextLines;
  size?: TextSizes;
  style?: CSSProperties;
}

export const Text = ({
  as: Element = 'p',
  children,
  className,
  size = 'base',
  noOfLines,
  ...props
}: TextProps) => {
  const sizeVariants: { [key in TextSizes]: string } = {
    '2xs': 'text-2xs',
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
  };

  const noOfLinesVariants: { [key in TextLines]: string } = {
    1: 'line-clamp-1',
    2: 'line-clamp-2',
    3: 'line-clamp-3',
    4: 'line-clamp-4',
    5: 'line-clamp-5',
    6: 'line-clamp-6',
  };

  return (
    <Element
      className={twMerge(
        `${sizeVariants[size]} ${
          noOfLines ? `${noOfLinesVariants[noOfLines]} text-ellipsis` : ''
        }`,
        className
      )}
      data-testid="aslan-text"
      {...props}
    >
      {children}
    </Element>
  );
};

export default Text;
